import moment from 'moment'
import { Color } from 'common'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export default {

  // toDateTime: (date_source) => {
  //   const date = new Date(date_source)

  //   if(isNaN(date.getTime()))
  //     return date_source

  //   const dateTimeFormat = new Intl.DateTimeFormat('ru', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }) 
  //   const [{ value: month },,{ value: day },,{ value: year },,{ value: hour },,{ value: minute }] = dateTimeFormat .formatToParts(date) 
    
  //   return `${day}.${month}.${year } ${hour}:${minute }`
  // },

  toDateTime: (date, time = true) => {
    const d = moment(date)
    return d.isValid() 
      ? d.format(time ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY')
      : date
  },

  secondsToHms: (seconds, has_milliseconds = true, time_title = false) => {
    seconds = Number(seconds)
    const ht = time_title ? ' ч ' : ':'
    const mt = time_title ? ' м ' : ':'
    const st = ' сек'
    const h = Math.floor(seconds / 3600)
    const m = Math.floor(seconds % 3600 / 60)
    let s = Math.floor(seconds % 3600 % 60)
    s = ('0' + s).slice(-2)

    if(has_milliseconds) {
      s = (seconds % 3600 % 60).toFixed(1)
      s = ('0' + s).slice(-4)
    }

    return (h ? ('0' + h).slice(-2) + ht : '') + 
            (m ? ('0' + m).slice(-2) + mt : '') +
            (s ? (s + st) : '')
  },

  secondToISOTime: sec => new Date(sec * 1000).toISOString().substr(11, 8),

  moment: date => moment(date),

  dateHm: date => moment(date).format('DD.MM.YYYY HH:mm'),

  today: format => moment().format(format ? format : 'YYYY-MM-DD'),
  week: format => moment().add(1, 'weeks').format(format ? format : 'YYYY-MM-DD'),
  month: format => moment().add(1, 'months').format(format ? format : 'YYYY-MM-DD'),
  quarter: format => moment().add(3, 'months').format(format ? format : 'YYYY-MM-DD'),
  year: format => moment().add(1, 'years').format(format ? format : 'YYYY-MM-DD'), //subtract

  ruWeek: w => {
    const d = ['','Пн','Вт','Ср','Чт','Пт','Сб','Вс']
    return w ? d[w] : d.filter(d => d)
  },

  ruFullWeek: w => {
    const d = ['','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота','Воскресенье']
    return w ? d[w] : d.filter(d => d)
  },

  ruMonth: m => {
    const d = ['','Январь','Февраль','Март','Апрель','Май','Июнь','Июля','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
    return m ? d[m] : d.filter(d => d)
  },

  barChartColors: percent => {
    if(!percent){
      return 'rgba(0,160,81, 0.1)'
    }
    if(percent <= 10){
      return 'rgba(0,160,81, 0.2)'
    }
    if(percent <= 20){
      return 'rgba(0,160,81, 0.2)'
    }
    if(percent <= 30){
      return 'rgba(0,160,81, 0.3)'
    }
    if(percent <= 40){
      return 'rgba(0,160,81, 0.4)'
    }
    if(percent <= 50){
      return 'rgba(0,160,81, 0.5)'
    }
    if(percent <= 60){
      return 'rgba(0,160,81, 0.6)'
    }
    if(percent <= 70){
      return 'rgba(0,160,81, 0.7)'
    }
    if(percent <= 80){
      return 'rgba(0,160,81, 0.8)'
    }
    if(percent <= 90){
      return 'rgba(0,160,81, 0.9)'
    }
    if(percent <= 100){
      return 'rgba(0,160,81, 1)'
    }
  },

  //icon = warning, error, success, info, question
  alert: (title, text = "", icon = 'info') => MySwal.fire(title, text, icon),

  confirm: async ({title, html, confirmButtonText, cancelButtonText = "Отмена"}) => {
    return MySwal.fire({
      title: title,
      html: html,
      icon: 'warning',
      showCancelButton: true,
      //confirmButtonColor: '#3085d6',
      //cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText
    })
  }



  
}