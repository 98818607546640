export default {
  
  players: {
    all: '', //?type=single,
    online: '?type=single&online=1',
    offline: '?type=single&online=0',
    inactive: '?type=single&activated=0',
  },

  player: {
    all: '',
    screenshots: '?type=screenshots',
    campaigns: '?type=campaigns',
  },

  campaigns: {
    all: '', //?type=normal&type=pipeline&exchange=0
    running: '?type=normal&type=pipeline&exchange=0&state=running',
    pending: '?type=normal&type=pipeline&exchange=0&state=pending',
    finished: '?type=normal&type=pipeline&exchange=0&state=finished',
    deleted: '?type=normal&type=pipeline&exchange=0&state=deleted',
  },

  fillers: {
    all: '', //?state=running&type=video&type=image&type=html&type=video_stream&programmatic=0
    pending: '?state=pending&type=video&type=image&type=html&type=video_stream&programmatic=0',
    finished: '?state=finished&type=video&type=image&type=html&type=video_stream&programmatic=0',
    finished_to: '?state=running&end=2020-09-07&type=video&type=image&type=html&type=video_stream&programmatic=0',
  },

}