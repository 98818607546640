const initialState = {
  data: [],
  isLoading: false
}

export default function contentsTag(state = initialState, action) {

  if (action.type === 'FETCH_CONTENTS_TAGS_PENDING') {
    return {
      ...state,
      isLoading: true
    }
  } else if (action.type === 'FETCH_CONTENTS_TAGS') {
    return {
      //...state,
      data: action.payload,
      isLoading: false
    } 
  }
  
  return state
}