const initialState = {
  meta: null,
  token: null,
  error: null,
  isLoading: false
}

export const login = (state = initialState, action) => {
  switch(action.type) {

    case 'LOGIN_PENDING':
      return { ...state, error: null, isLoading: true }

    case 'LOGIN_FAILED':
      return { ...state, error: 'Неправильный логин или пароль', isLoading: false }

    case 'LOGIN': 
      return { ...action.payload, error: null, isLoading: false }

    case 'LOGOUT':
      console.log('LOGOUt')
      return { ...initialState }
    
    default:
      return state

  }
}
