import React from "react"
import * as Icon from "react-feather"

// https://pixinvent.com/demo/vuexy-react-admin-dashboard-template/documentation/development/menu/vertical-menu

// permissions: ["admin", "editor"], - также в Sidebar
// потребутеся передать пропсы с правами
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "collapse",
    icon: <Icon.Layout size={20} />,
    //navLink: "/",
    children: [
      {
        id: "widgets",
        title: "Widgets",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/"
      },
      {
        id: "players-bar-chart",
        title: "Players",
        type: "item",
        icon: <Icon.Circle size={10} />,
        navLink: "/home/players-barchart"
      }
    ]
  },
  {
    id: "players",
    title: "Players",
    type: "item",
    navLink: "/players",
  },
  {
    id: "content",
    title: "Content",
    type: "item",
    navLink: "/content",
  },
  {
    id: "campaigns",
    title: "Campaigns",
    type: "item",
    navLink: "/campaigns",
  },
  {
    id: "analytics",
    title: "Analytics",
    type: "item",
    navLink: "/analytics",
  },
  {
    id: "settings",
    title: "Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    navLink: "/settings",
  }
]

export default navigationConfig
