import { reqAPI } from 'services'


export const fetchPlayers = (search = '') => async dispatch => {

  dispatch({ type: 'FETCH_PLAYERS_PENDING' })

  return reqAPI.get('players' + search)
  .then(function (response) {
    dispatch({
      type: 'FETCH_PLAYERS',
      payload: {
        ...response.data
      },
    })
  })
  .catch(function (error) {
    reqAPI.error(dispatch, error.response.status)
  })
  
}

export const fetchPlayer = id => async dispatch => {

  dispatch({ type: 'FETCH_PLAYER_PENDING' })

  return reqAPI.get('players/' + id)
  .then(function (response) {
    //console.log(response.data)
    dispatch({
      type: 'FETCH_PLAYER',
      payload: {
        ...response.data
      },
    })
  })
  .catch(function (error) {
    reqAPI.error(dispatch, error.response.status)
  })
  
}

export const addPlayer = data => dispatch => {

  dispatch({ type: 'CREATE_PLAYER_PENDING' })

  return reqAPI.post('players', {
    ...data
  }).then(function(response) {
    //response.created_at
    dispatch({ type: 'CREATE_PLAYER_SUCCESS' })
    return response.data
  })
  .catch(function (error) {
    console.log(error)
    //dispatch()
  })

}

export const editPlayer = (id, data) => dispatch => {

  dispatch({ type: 'UPDATE_PLAYER_PENDING' })

  return reqAPI.put('players/' + id, {
    ...data
  }).then(function(response) {
    dispatch({ type: 'UPDATE_PLAYER_SUCCESS' })
    return response.data
  })
  .catch(function (error) {
    console.log(error)
    //dispatch()
  })

}

export const deletePlayer = (id) => dispatch => {

  //dispatch({ type: 'DELETE_PLAYER_PENDING' })

  return Promise.allSettled([ //all
    reqAPI.delete('players/' + id + '/campaigns'),
    reqAPI.delete('players/' + id)
  ]).then(function(values) {
    return true
    //dispatch({ type: DELETE_PLAYER_SUCCESS })
  })

}