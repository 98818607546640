import { reqAPI } from 'services'


export const fetchMe = (search = '') => dispatch => {

  dispatch({ type: 'FETCH_ME_PENDING' })

  console.log('response')

  reqAPI.get('me' + search)
  .then(function (response) {
    console.log(response.data)
    dispatch({
      type: 'FETCH_ME',
      payload: {
        ...response.data
      },
    })
  })
  .catch(function (error) {
    reqAPI.error(dispatch, error.response.status)
  })
  
}