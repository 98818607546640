import { reqAPI } from 'services'

export const fetchCompanies = (search = '') => dispatch => {

  dispatch({ type: 'FETCH_CONTENTS_PENDING' })

  reqAPI.get('odvertisers' + search)
  .then(function (response) {
    dispatch({
      type: 'FETCH_CONTENTS',
      payload: {
        ...response.data
      },
    })
  })
  .catch(function (error) {
    reqAPI.error(dispatch, error.response.status)
  })
  
}

export const fetchCompany = id => async dispatch => {

  dispatch({ type: 'FETCH_CONTENTS_PENDING' })

  return reqAPI.get(`odvertisers/${id}`)
  .then(function (response) {
    dispatch({
      type: 'FETCH_CONTENTS',
      payload: {
        ...response.data
      },
    })
  })
  .catch(function (error) {
    reqAPI.error(dispatch, error.response.status)
  })
  
}

export const fetchContents = (search = '') => dispatch => {

  dispatch({ type: 'FETCH_CONTENTS_PENDING' })

  reqAPI.get('content/items' + search)
  .then(function (response) {
    dispatch({
      type: 'FETCH_CONTENTS',
      payload: {
        ...response.data
      },
    })
  })
  .catch(function (error) {
    reqAPI.error(dispatch, error.response.status)
  })
  
}
