import React from "react"
// import { browserHistory } from "react-router";
import {
  UncontrolledDropdown,
  NavLink,
  NavItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Badge
} from "reactstrap"
import Avatar from "components/@vuexy/avatar/AvatarComponent"
import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import classnames from "classnames"
import ReactCountryFlag, { values } from "react-country-flag"
// import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent"
import Sidebar from "react-sidebar"
import { X } from "react-feather"
import { history } from "../../../history"
import { connect } from 'react-redux'
import { IntlContext } from "utility/context/Internationalization"
import { Config, Formatter } from 'common'
import { reqAPI } from 'services'

import { logout } from 'redux/actions/auth/loginActions'
import { fetchMe } from 'redux/actions/me'
import { fetchPlayers } from 'redux/actions/player'
import { fetchCompanies } from 'redux/actions/company'
import { fetchContents } from 'redux/actions/content'
import { fetchCampaigns } from 'redux/actions/campaign'

import { ToastContainer, toast } from "react-toastify"
import Tags from "views/ui-elements/Tag"

import "react-toastify/dist/ReactToastify.css"

import "assets/scss/plugins/extensions/toastr.scss"

const UserDropdown = props => {
  return (
    <DropdownMenu right>
      <DropdownItem tag="span" onClick={() => history.push('/settings/password')}>
        <Icon.Pocket size={14} className="mr-50" />
        <span className="align-middle">Безопасность</span>
      </DropdownItem>
      <DropdownItem tag="span" onClick={() => history.push('/settings/profile')}>
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Профиль</span>
      </DropdownItem>
      {/* <DropdownItem tag="a" href="#">
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem> */}
      <DropdownItem divider />
      <DropdownItem
        tag="a"
        onClick={props.logout}
      >
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Выйти</span>
      </DropdownItem>
    </DropdownMenu>
  )
}

class NavbarUser extends React.PureComponent {
  constructor(props) {
    super(props);
   
    this.state = {
      navbarSearch: false,
      langDropdown: false,
      suggestions: [],
      keyword: "",
      //sync: [],
      notifications: [],
      manual_notifications: [],
      sidebarState: false
    }

    this.searchRef = React.createRef();
  }

  componentDidMount() {
    this.props.fetchMe()

    this.notifyInterval = setInterval(() => {
      this.onNotify()
    }, Config.NOTIFY_TIMEOUT)

    this.onNotify()

    this.unlisten = history.listen((location, action) => {
      const s = new URLSearchParams(window.location.search.split("?")[1])
      if (!s.has("name")) {
        this.setState({
          navbarSearch: false,
          keyword: ""
        })
      }
    });
  }

  componentWillUnmount() {
    clearInterval(this.notifyInterval)
    this.unlisten();
  }

  onNotify = async () => {
    //const sync = await reqAPI.get('players?od_campaign_dirty=1')
    const notifications = await reqAPI.get('notifications')
    const manual_notifications = await reqAPI.get('manual_notifications')
    
    manual_notifications.data
    .filter(s => this.state.manual_notifications
    .findIndex(m => s.id === m.id) === -1)
    .map(m => {
      const info = 
        <div className="m-notify">
          <Badge pill color="primary" className={`${m.body.tag.color}`} key={m.id}>
            {m.body.tag.text}
          </Badge>
          <div dangerouslySetInnerHTML={{__html: m.body.text}}></div>
        </div>
      return toast.info(info, {
        autoClose: false,
        onClose: (e) => {
          const id = +e.children[0].key
          reqAPI.patch('manual_notifications/' + id, {dismissed: true})
        }
      })
    })

    this.setState({
      //sync: sync.data.objects,
      notifications: notifications.data,
      manual_notifications: manual_notifications.data,
    })
  }
  
  // onSync = async () => {
  //   const res = await reqAPI.post(`od/players/update/?dirty=1`, {})
  //   this.setState({sync: []})
  // }

  handleNavbarSearch = () => {
    if (!this.state.navbarSearch) {
      setTimeout(() => this.searchRef.current.focus(), 200)
    }

    this.setState({ navbarSearch: !this.state.navbarSearch })
  }

  handleSearchChange = (e) => {
    // if(e.target.value.length < 3) return
    this.setState({ keyword: e.target.value })

    const paths = window.location.pathname.split("/")
    const offset = 0
    const limit = Config.PER_TO_PAGE

    const searchParams = new URLSearchParams(window.location.search.split("?")[1])
    if (e.target.value)
      searchParams.set("name", e.target.value)
    else
      searchParams.delete("name")
    const s = `?${(searchParams.toString())}`
    history.push(s)

    // !!!!!!! При переключении кнопок удалять поиск!!!!!!!

    if(paths[1] === "players") {
      return this.props.fetchPlayers(`${s}&limit=${limit}&offset=${offset}`)
    } else if(paths[1] === "content") {
      if (paths[2] !== "items") { // компании
        return this.props.fetchCompanies(`${s}&order_by=id.asc&limit=-1`)
      } else { // контент
        return this.props.fetchContents(`${s}&odvertiser_id=${paths[4]}&limit=-1`)
      }
      
    } else if(paths[1] === "campaigns") {
      return this.props.fetchCampaigns(`${s}&limit=${limit}&offset=${offset}`)
    }
  }

  handleLangDropdown = () => {
    this.setState({langDropdown: !this.state.langDropdown})
  }

  onSetSidebarToggle = () => {
    this.setState({sidebarState: !this.state.sidebarState})
  }

  render() {
    const { me } = this.props
    // console.log("thtrh", new URLSearchParams(window.location.search.split("?")[1]))

    return (
    <div>
      <ul className="nav navbar-nav navbar-nav-user float-right">

        {/* {this.state.sync?.length > 0 &&
        <NavItem className="position-relative mr-1" onClick={this.onSync}>
          <NavLink className="nav-link-sync">
            <Icon.RefreshCw size={21} id="sync-badge" />
            <Badge pill color="danger" className="badge-up">
              {this.state.sync.length}
            </Badge>
            <UncontrolledTooltip placement="bottom" target="sync-badge">
              Синхронизировать устройств: {this.state.sync.length}
            </UncontrolledTooltip>
          </NavLink>
        </NavItem>} */}

        <NavItem className="nav-search">
          <NavLink className="nav-link-search" onClick={this.handleNavbarSearch}>
            <Icon.Search size={21} data-tour="search" />
          </NavLink>
          <div
            className={classnames("search-input", {
              open: this.state.navbarSearch,
              "d-none": this.state.navbarSearch === false
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary" />
            </div>

            <div className="vx-autocomplete-container">
              <input
                ref={this.searchRef}
                type="text"
                value={this.state.keyword}
                onChange={this.handleSearchChange}
                className="vx-autocomplete-search form-control"
                placeholder="Начинайте "
              />
            </div>

            {/* <Autocomplete
              className="form-control"
              suggestions={this.state.suggestions}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped={true}
              placeholder="Искать..."
              autoFocus={true}
              clearInput={this.state.navbarSearch}
              externalClick={e => {
                this.setState({ navbarSearch : false })
              }}
              onKeyDown={e => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                const IconTag = Icon[item.icon ? item.icon : "X"]
                return (
                  <li
                    className={classnames("suggestion-item", {
                      active: filteredData.indexOf(item) === activeSuggestion
                    })}
                    key={i}
                    onClick={e => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div
                      className={classnames({
                        "d-flex justify-content-between align-items-center":
                          item.file || item.img
                      })}
                    >
                      <div className="item-container d-flex">
                        {item.icon ? (
                          <IconTag size={17} />
                        ) : item.file ? (
                          <img
                            src={item.file}
                            height="36"
                            width="28"
                            alt={item.title}
                          />
                        ) : item.img ? (
                          <img
                            className="rounded-circle mt-25"
                            src={item.img}
                            height="28"
                            width="28"
                            alt={item.title}
                          />
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.title}</p>
                          {item.by || item.email ? (
                            <small className="text-muted">
                              {item.by
                                ? item.by
                                : item.email
                                ? item.email
                                : null}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className="meta-container">
                          <small className="text-muted">
                            {item.size
                              ? item.size
                              : item.date
                              ? item.date
                              : null}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                )
              }}
              onSuggestionsShown={userInput => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput)
                }
              }}
            /> */}
            <div className="search-input-close">
              <Icon.X
                size={24}
                onClick={(e) => {
                  e.stopPropagation()
                  this.setState({
                    navbarSearch: false
                  })
                  this.props.handleAppOverlay("")
                }}
              />
            </div>
          </div>
        </NavItem>

        <NavItem className="position-relative" onClick={this.onSetSidebarToggle}>
          <NavLink className="nav-link-tag">
            <Icon.Tag size={21} data-tour="tag" />
          </NavLink>
        </NavItem>

        <IntlContext.Consumer>
          {context => {
            let langArr = {
              "en" : "English",
              "ru" : "Русский"
            }
            return (
              <Dropdown
                tag="li"
                className="dropdown-language nav-item"
                isOpen={this.state.langDropdown}
                toggle={this.handleLangDropdown}
                data-tour="language"
              >
                <DropdownToggle
                  tag="a"
                  className="nav-link"
                >
                  <ReactCountryFlag
                  className="country-flag"
                    countryCode={
                      context.state.locale === 'en' ? 'us' : context.state.locale
                    }
                    svg
                  />
                  {/* <span className="d-sm-inline-block d-none text-capitalize align-middle ml-50">
                    {langArr[context.state.locale]}
                  </span> */}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("en")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="us" svg />
                    <span className="ml-1">English</span>
                  </DropdownItem>
                  <DropdownItem
                    tag="a"
                    onClick={e => context.switchLanguage("ru")}
                  >
                    <ReactCountryFlag className="country-flag" countryCode="ru" svg />
                    <span className="ml-1">Русский</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )
          }}
        </IntlContext.Consumer>
        
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <Icon.Bell size={21} />
            <Badge pill color="success" className="badge-up">
              {this.state.notifications.length}
            </Badge>
          </DropdownToggle>
          <DropdownMenu tag="ul" right className="dropdown-menu-media">
            <li className="dropdown-menu-header">
              <div className="dropdown-header mt-0">
                <h3 className="text-white">
                  {this.state.notifications.length} новых
                </h3>
                <span className="notification-title">Уведомления</span>
              </div>
            </li>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative"
              options={{
                wheelPropagation: false
              }}
            >
              {this.state.notifications.map(n => {
                const titleClass = {
                  players_offline: "info",
                  players_offline_1day: "warning",
                  ad_campaigns_end_3days: "danger"
                }
                
                return (
                  <div className="d-flex justify-content-between" key={n.id}>
                    <Media className="d-flex align-items-start">
                      <Media left href="#">
                        <Icon.AlertTriangle
                          className={`font-medium-5 ${titleClass[n.name] || 'info'}`}
                          size={21}
                        />
                      </Media>
                      <Media body>
                      <Media heading className={`${titleClass[n.name] || 'info'} media-heading`} tag="h6">
                          {n.title.ru || n.title.en}
                        </Media>
                      </Media>
                      <small>
                        <time className="media-meta">
                          {Formatter.toDateTime(n.datetime)}
                        </time>
                      </small>
                    </Media>
                  </div>
                )
              })}
            </PerfectScrollbar>
            <li className="dropdown-menu-footer">
              <DropdownItem tag="a" className="p-1 text-center">
                <span className="align-middle"
                  onClick={() => history.push("/notifications")}
                >Читать все уведомления</span>
              </DropdownItem>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {me.profile?.first_name} {me.profile?.last_name}
              </span>
              <span className="user-status">{me.email}</span>
            </div>
            <span data-tour="user">
              {me.profile && me.profile.avatar ?
              <img src={me.profile.avatar}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              /> : <Avatar content={me.profile?.first_name.substr(0, 2)} />}
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>

      <ToastContainer style={{width: "50%"}} />

      <Sidebar
        styles={{
          root: {top: 75},
          sidebar: {
            position: "fixed",
          },
        }}
        sidebar={
          <div className="content-sidebar">
            <div className="content-sidebar-header">
              <div className="d-flex justify-content-between">
                <h4 className="mb-0">Теги</h4>
                <div onClick={this.onSetSidebarToggle}>
                  <X size={15} />
                </div>
              </div>
            </div>
            <div className="content-sidebar-body">
              <Tags />
            </div>
          </div>
        }
        sidebarClassName="bg-white"
        //contentClassName="d-none"
        overlayClassName="d-none"
        open={this.state.sidebarState}
        pullRight={true}
      >{""}</Sidebar>

    </div>)
  }
}


const mapStateToProps = store => {
  return {
    me: store.me,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchMe: () => dispatch(fetchMe()),
    logout: () => dispatch(logout()),
    fetchPlayers: searchObj => dispatch(fetchPlayers(searchObj)),
    fetchCompanies: searchObj => dispatch(fetchCompanies(searchObj)),
    fetchContents: searchObj => dispatch(fetchContents(searchObj)),
    fetchCampaigns: searchObj => dispatch(fetchCampaigns(searchObj)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavbarUser)