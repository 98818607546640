const initialState = {
  //meta: {},
  //objects: [],
  isLoading: false,
  hasError: false
}

export default function campaigns(state = initialState, action) {

  switch(action.type) {
    case 'FETCH_CAMPAIGNS_PENDING':
      return {
        ...state, isLoading: true, hasError: false
      }
    case 'FETCH_CAMPAIGNS':
      return {
        ...action.payload, isLoading: false, hasError: false
      } 

    case 'FETCH_CAMPAIGN_PENDING':
      return {
        ...state, isLoading: true, hasError: false
      }
    case 'FETCH_CAMPAIGN':
      return { 
        ...state, object: { ...action.payload }, isLoading: false, hasError: false
      } 

    // case CREATE_CAMPAIGN_PENDING:
    //   return {
    //     ...state, isFetching: true, hasError: false
    //   }
    // case CREATE_CAMPAIGN_SUCCESS:
    //   return {
    //     ...state, isFetching: false, hasError: false
    //   } 
    // case CREATE_CAMPAIGN_FAIL:
    //   return {
    //     ...state, isFetching: false, hasError: true
    //   } 

    // case UPDATE_CAMPAIGN_PENDING:
    //   return {
    //     ...state, isFetching: true, hasError: false
    //   }
    // case UPDATE_CAMPAIGN_SUCCESS:
    //   return {
    //     ...state, isFetching: false, hasError: false
    //   } 
    // case UPDATE_CAMPAIGN_FAIL:
    //   return {
    //     ...state, isFetching: false, hasError: true
    //   } 

    // case DELETE_CAMPAIGN_PENDING:
    //   return {
    //     ...state, isFetching: true, hasError: false
    //   }
    // case DELETE_CAMPAIGN_SUCCESS:
    //   return {
    //     ...state, isFetching: false, hasError: false
    //   } 
    // case DELETE_CAMPAIGN_FAIL:
    //   return {
    //     ...state, isFetching: false, hasError: true
    //   }
    default: 
      return state
  }
  
}