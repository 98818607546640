
const initialState = {
  meta: {},
  objects: [],
  isLoading: false,
  hasError: false
}

export default function advertiser(state = initialState, action) {

  switch(action.type) {
    case 'FETCH_ADVERTISERS_PENDING':
      return {
        ...state, isLoading: true, hasError: false
      }
    case 'FETCH_ADVERTISERS':
      return {
        ...state, ...action.payload, isLoading: false, hasError: false
      }
    default: 
      return state
  }
  
}