import { reqAPI } from 'services'

export const login = authData => async dispatch => {
  dispatch({ type: 'LOGIN_PENDING' })
  // Get auth token
  await reqAPI.login(authData).then(function(response) {
    const token = response.data.access_token
    if(token) {
      // Get user info
      reqAPI.me({
        headers: {
          'authorization': 'OAuth ' + token
        }
      }).then(function(me) {
        const user = {
          meta: me.data,
          token: token
        }
        reqAPI.user.add(user)
        dispatch({
          type: 'LOGIN',
          payload: {
            ...user
          },
        })
      })
    }
  })
  .catch(function (error) {
    dispatch({ type: 'LOGIN_FAILED' })
  })
}

export const logout = () => dispatch => {
  reqAPI.user.logout(dispatch)
}