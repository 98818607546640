import { reqAPI } from 'services'


export const fetchContentsTags = (query = '') => dispatch => {

  dispatch({ type: 'FETCH_CONTENTS_TAGS_PENDING' })

  reqAPI.get('content/items_tags' + query)
  .then(function (response) {
    //console.log(response.data)
    dispatch({
      type: 'FETCH_CONTENTS_TAGS',
      payload: response.data,
    })
  })
  .catch(function (error) {
    reqAPI.error(dispatch, error.response.status)
  })
  
}