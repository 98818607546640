import React from "react"
import { withRouter } from "react-router-dom"
import { connect } from 'react-redux'
import { Label, FormGroup, ListGroup, ListGroupItem, Button, ButtonGroup } from "reactstrap"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import { Tag, Trash2 } from "react-feather"
import { reqAPI } from 'services'
//import FabAction from 'views/ui-elements/buttons/FabAction'
//import { Color } from "common"
import { fetchPlayersTags } from 'redux/actions/playersTag'
import { fetchContentsTags } from 'redux/actions/contentsTag'

import "assets/scss/plugins/forms/react-select/_react-select.scss"

const formSchema = Yup.object().shape({
  name: Yup.string().required("Обязательно"),
})

class Tags extends React.Component {
  
  state = {
    form_state: {
      name: "",
    },
    tag_type: 'players_tags',
    success: false
  }

  componentDidMount() {
    this.fetchTags()
    //reqAPI.get(`/license`).then(res => console.log(res))
  }

  fetchTags = () => {
    this.props.fetchPlayersTags()
    this.props.fetchContentsTags()
  }

  setBtnType = tag_type => this.setState({tag_type})

  onFinish = async (values, { resetForm, setSubmitting }) => {
    const { tag_type } = this.state

    const payload = {
      name: values.name,
    }

    try {
      const res = await reqAPI.post(`${tag_type}/`, payload)
      this.setState({
        success: `Тег "${values.name}" добавлен`,
      })
      this.fetchTags()
    } catch(error) { console.error(error) }

    resetForm()
    //setSubmitting(false)
  }

  onRemove = async tag => {
    const { tag_type } = this.state
    try {
      await reqAPI.delete(`${tag_type}/${tag.id}`)
      this.setState({
        success: `Тег "${tag.name}" удален`,
      })
      this.fetchTags()
    } catch(error) { console.error(error) }
  }

  side_nav = []

  render() {
    const { playersTag, contentsTag } = this.props
    const { success, tag_type, form_state } = this.state

    const current_tags = tag_type === 'players_tags' ? playersTag : contentsTag

    return(

      <>

        <div className="mb-1 text-center">
          <ButtonGroup>
            <Button.Ripple
              color="primary" 
              outline={tag_type !== 'players_tags'}
              onClick={() => this.setBtnType('players_tags')}
            >
              Устройства
            </Button.Ripple>
            <Button.Ripple
              color="primary" 
              outline={tag_type !== 'content/items_tags'}
              onClick={() => this.setBtnType('content/items_tags')}
            >
              Контент
            </Button.Ripple>
          </ButtonGroup>
        </div>

        <Formik
          enableReinitialize
          initialValues={form_state}
          validationSchema={formSchema}
          onSubmit={(values, props) => this.onFinish(values, props)}
        >
        {({ values, errors, touched, setFieldValue }) => {
          return(
            <Form>
              <FormGroup>
                <Label for="tag-name">Название тега</Label>
                <Field id="tag-name" name="name"
                  className={`form-control ${errors.name && touched.name && "is-invalid"}`}
                />
              </FormGroup>
              <FormGroup className="text-right">
                <Button.Ripple color="success" type="submit">
                  Сохранить
                </Button.Ripple>
              </FormGroup>
            </Form>
          )
        }}
        </Formik>

        {/* <Alert color="success" isOpen={!!success} toggle={() => this.setState({success: false})}>
          {success}
        </Alert> */}

        <ListGroup>
          {current_tags.data?.map(t =>
          <ListGroupItem className="d-flex justify-content-between align-items-center" key={t.id}>
            <span><Tag size={13} className="mr-50" /> {t.name}</span>
            <Button.Ripple color="flat-danger" size="sm" onClick={() => this.onRemove(t)}>
              <Trash2 size={14} />
            </Button.Ripple>
          </ListGroupItem>
          )}
        </ListGroup>
      </>
    )
  }

}


const mapStateToProps = store => {
  return {
    playersTag: store.playersTag,
    contentsTag: store.contentsTag,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    fetchPlayersTags: q => dispatch(fetchPlayersTags(q)),
    fetchContentsTags: q => dispatch(fetchContentsTags(q)),
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Tags))