import React from "react"
import * as Icon from "react-feather"

//https://pixinvent.com/demo/vuexy-react-admin-dashboard-template/documentation/development/menu/horizontal-menu

// permissions: ["admin", "editor"], - также в HorizontalMenu
// потребутеся передать пропсы с правами
const horizontalMenuConfig = [
  {
    id: "home",
    type: "item",
    icon: <Icon.Layout size={20} />,
    navLink: "/",
  },
  {
    id: "players",
    title: "Players",
    type: "item",
    navLink: "/players",
  },
  {
    id: "content",
    title: "Content",
    type: "item",
    navLink: "/content",
  },
  {
    id: "campaigns",
    title: "Campaigns",
    type: "item",
    navLink: "/campaigns",
  },
  {
    id: "analytics",
    title: "Analytics",
    type: "item",
    navLink: "/analytics",
  },
  // {
  //   id: "settings",
  //   type: "item",
  //   icon: <Icon.Settings size={20} />,
  //   navLink: "/settings",
  // }
]

export default horizontalMenuConfig
