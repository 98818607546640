const initialState = {
  //meta: {},
  //objects: [],
  isLoading: false,
  hasError: false
}

export default function content(state = initialState, action) {

  switch(action.type) {
    case 'FETCH_CONTENTS_PENDING':
      return {
        isLoading: true, hasError: false
      }
    case 'FETCH_CONTENTS':
      return {
        ...action.payload, isLoading: false, hasError: false
      }
    default: 
      return state
  }
  
}