import axios from 'axios'
import { Config } from 'common'

export const reqAPI = {

  get: (path = null, data = {}) => axios.get(Config.API_ROOT + path, {
    headers: {
      'authorization': 'OAuth ' + reqAPI.user.get().token
    },
    ...data,
  }),
  post: (path = null, data = {}, config = {}) => axios.post(Config.API_ROOT + path, {
    ...data,
    }, {
    headers: {
      'authorization': 'OAuth ' + reqAPI.user.get().token
    },
    ...config,
  }),
  put: (path = null, data = {}, config = {}) => axios.put(Config.API_ROOT + path, {
    ...data,
    }, {
    headers: {
      'authorization': 'OAuth ' + reqAPI.user.get().token
    },
    ...config,
  }),
  patch: (path = null, data = {}, config = {}) => axios.patch(Config.API_ROOT + path, {
    ...data,
    }, {
    headers: {
      'authorization': 'OAuth ' + reqAPI.user.get().token
    },
    ...config,
  }),
  delete: (path = null, config = {}) => axios.delete(Config.API_ROOT + path, {
    headers: {
      'authorization': 'OAuth ' + reqAPI.user.get().token
    },
    ...config,
  }),

  outer_get: (path = null, data = {}) => axios.get(path, {
    ...data,
  }),

  //https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
  download_file: (path) => axios({
    url: Config.URL_ROOT + path,
    method: 'GET',
    responseType: 'blob',
    headers: {'Access-Control-Allow-Origin': '*'}
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    //
    const contentDisposition = response.headers['content-disposition']
    let fileName = 'unknown'
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
      if (fileNameMatch.length === 2)
        fileName = fileNameMatch[1]
    }
    //
    link.setAttribute('download', fileName) //'file.pdf'
    document.body.appendChild(link)
    link.click()
  }),

  login: authData => axios.post(Config.API_ROOT + Config.endpoints.login, authData),
  me: userData => axios.get(Config.API_ROOT + Config.endpoints.me, userData),

  user: {
    add: (user) => window.localStorage.setItem('user', JSON.stringify(user)),
    get: () => JSON.parse(window.localStorage.getItem('user')),
    // Войти как (из админки)
    getImpersonate: async (impersonateToken) => {
      // Get user info
      return reqAPI.me({
        headers: {
          'authorization': 'OAuth ' + impersonateToken
        }
      }).then(function(me) {
        const user = {
          meta: me.data,
          token: impersonateToken
        }
        reqAPI.user.add(user)
        window.location = '/'
        //return reqAPI.user.get()
      })
    },
    remove: () => window.localStorage.removeItem('user'),
    logout: (dispatch) => {
      window.localStorage.removeItem('user')
      dispatch({ type: 'LOGOUT' })
    },
  },

  error: (dispatch, code) => {
    switch(code) {
      case 401:
        reqAPI.user.logout(dispatch)
      default:
        return null
    }
  }

}
