import { reqAPI } from 'services'


export const fetchCampaigns = (search = '') => async dispatch => {

  dispatch({ type: 'FETCH_CAMPAIGNS_PENDING' })

  return reqAPI.get('od/campaigns' + search)
  .then(function (response) {
    dispatch({
      type: 'FETCH_CAMPAIGNS',
      payload: {
        ...response.data
      },
    })
  })
  .catch(function (error) {
    reqAPI.error(dispatch, error.response.status)
  })
  
}

export const fetchCampaign = id => async dispatch => {

  dispatch({ type: 'FETCH_CAMPAIGN_PENDING' })

  return reqAPI.get('od/campaigns/' + id)
  .then(function (response) {
    dispatch({
      type: 'FETCH_CAMPAIGN',
      payload: {
        ...response.data
      },
    })
  })
  .catch(function (error) {
    reqAPI.error(dispatch, error.response.status)
  })
  
}


// export const addCampaign = data => dispatch => {

//   dispatch({ type: CREATE_CAMPAIGN_PENDING })

//   reqAPI.post('od/campaigns', {
//     ...data
//   }).then(function(response) {
//     console.log(response)
//     dispatch({ type: CREATE_CAMPAIGN_SUCCESS })
//   })
//   .catch(function (error) {
//     console.log(error)
//     dispatch({ type: CREATE_CAMPAIGN_FAIL })
//   })

// }

// export const editCampaign = (id, data) => dispatch => {

//   dispatch({ type: UPDATE_CAMPAIGN_PENDING })

//   reqAPI.put('od/campaigns/' + id, {
//     ...data
//   }).then(function(response) {
//     dispatch({ type: UPDATE_CAMPAIGN_SUCCESS })
//   })
//   .catch(function (error) {
//     console.log(error)
//     //dispatch()
//   })

// }