import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"

import player from './player'
import playersTag from './playersTag'
import contentsTag from './contentsTag'
import campaign from './campaign'
import organization from './organization'
import advertiser from './advertiser'
import company from './company'
import content from './content'
import analytics from './analytics'
import application from './application'
import me from './me'
import filler from './filler'
import fillerCategories from './fillerCategories'
import user from './user'
import role from './role'
import reboot from './reboot'
import brightness from './brightness'
import statistic from './statistic'

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  navbar: navbar,

  player,
  playersTag,
  contentsTag,
  campaign,
  organization,
  advertiser,
  company,
  content,
  analytics,
  application,
  me,
  filler,
  fillerCategories,
  user,
  role,
  reboot,
  brightness,
  statistic
})

export default rootReducer
