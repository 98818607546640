const initialState = {
  //meta: {},
  //objects: [],
  isLoading: false,
  hasError: false
}

export default function me(state = initialState, action) {

  switch(action.type) {
    case 'FETCH_ME_PENDING':
      return {
        ...state, isLoading: true, hasError: false
      }
    case 'FETCH_ME':
      return {
        ...action.payload
      } 
    default: 
      return state
  }
  
}