import { reqAPI } from 'services'


export const fetchPlayersTags = (query = '') => dispatch => {

  dispatch({ type: 'FETCH_PLAYERS_TAGS_PENDING' })

  reqAPI.get('players_tags' + query)
  .then(function (response) {
    //console.log(response.data)
    dispatch({
      type: 'FETCH_PLAYERS_TAGS',
      payload: response.data,
    })
  })
  .catch(function (error) {
    reqAPI.error(dispatch, error.response.status)
  })
  
}