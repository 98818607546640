const initialState = {
    //meta: {},
    //objects: [],
    isLoading: false,
    hasError: false
  }
  
  export default function reboot(state = initialState, action) {
  
    switch(action.type) {
      case 'FETCH_BRIGHTNESS_PENDING':
        return {
          ...state, isLoading: true, hasError: false
        }
      case 'FETCH_BRIGHTNESS':
        return {
          ...action.payload, isLoading: false, hasError: false
        }
      default: 
        return state
    }
    
  }