const initialState = {
  meta: {},
  objects: [],
  isLoading: false
}

export default function fillerCategories(state = initialState, action) {

  if (action.type === 'FETCH_FILLER_CATEGORIES_PENDING') {
    return {
      ...state,
      isLoading: true
    }
  } else if (action.type === 'FETCH_FILLER_CATEGORIES') {
    return {
      //...state,
      ...action.payload,
      isLoading: false
    } 
  }
  
  return state
}