export default {
    //primary: '#f1573d', //rgb(241,87,61)
    // secondary: '#2f4858', //rgb(47,72,88)
    // purple: '#722ed1',
    // geekblue: '#2f54eb',
    // white: '#fff',
    // // like google
    // blue: '#1890ff',
    // red: '#EC4235',
    // green:'#33A955',
    // yellow: '#FABC05',

    // Vuexy
    primary: "#7367F0", 
    success: "#28C76F", 
    info: "#00cfe8", 
    warning: "#FF9F43", 
    danger: "#EA5455",
    black: "#1e1e1e",
    gray: '#babfc7',
    light: '#f8f8f8'

}