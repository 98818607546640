const initialState = {
  //meta: {},
  //objects: [],
  //object: {},
  isLoading: false,
  isError: false
}

export default function players(state = initialState, action) {

  if (action.type === 'FETCH_PLAYERS_PENDING') {
    return {
      ...state,
      isLoading: true
    }
  } else if (action.type === 'FETCH_PLAYERS') {
    return {
      //...state,
      ...action.payload,
      isLoading: false
    } 
  } if (action.type === 'FETCH_PLAYER_PENDING') {
    return {
      ...state,
      isLoading: true
    }
  } else if (action.type === 'FETCH_PLAYER') {
    return {
      ...state,
      object: { ...action.payload },
      isLoading: false
    } 
  } else if (action.type === 'CREATE_PLAYER_PENDING') {
    return {
      //...state,
      isLoading: true,
    }
  } else if (action.type === 'CREATE_PLAYER_SUCCESS') {
    return {
      //...state,
      isLoading: false,
    } 
  } else if (action.type === 'UPDATE_PLAYER_PENDING') {
    return {
      //...state,
      isLoading: true,
    }
  } else if (action.type === 'UPDATE_PLAYER_SUCCESS') {
    return {
      //...state,
      isLoading: false,
    } 
  } 
  // else if (action.type === DELETE_PLAYER_PENDING) {
  //   return {
  //     ...state,
  //     isFetching: true,
  //   }
  // } else if (action.type === DELETE_PLAYER_SUCCESS) {
  //   return {
  //     ...state,
  //     isFetching: false,
  //   } 
  // } 
  // else if (action.type === DELETE_PLAYER_FAIL) {
  //   return {
  //     ...state,
  //     isFetching: false,
  //     isError: true
  //   } 
  // }
  
  return state
}